import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const CarouselElement = () => {
  return (
    <Carousel dynamicHeight="false">
      <div>
        <img src={require('../pictures/grand-celeb-19.jpg')} alt="ACS 2023 grand celebration event teachers" />
      </div>
      <div>
        <img loading="lazy" src={require('../pictures/grand-celeb-21.jpg')} alt="ACS 2023 grand celebration audience" />
      </div>
      <div>
        <img loading="lazy" src={require('../pictures/grand-celeb-2.jpg')} alt="ACS 2023 grand celebration apar and BUET first" />
      </div>
      <div>
        <img loading="lazy" src={require('../pictures/grand-celeb-17.jpg')} alt="ACS 2023 grand celebration teacher giving prize" />
      </div>
      <div>
        <img loading="lazy" src={require('../pictures/grand-celeb-18.jpg')} alt="ACS 2023 grand celebration confetti" />
      </div>
      <div>
        <img loading="lazy" src={require('../pictures/grand-celeb-22.jpg')} alt="ACS 2023 grand celebration audience view" />
      </div>
      <div>
        <img loading="lazy" src={require('../pictures/grand-celeb-5.jpg')} alt="ACS 2023 grand celebration night light" />
      </div>
      <div>
        <img loading="lazy" src={require('../pictures/grand-celeb-16.jpg')} alt="ACS 2023 grand celebration student sharing story" />
      </div>
      <div>
        <img loading="lazy" src={require('../pictures/grand-celeb-6.jpg')} alt="ACS 2023 grand celebration student singing" />
      </div>
      
      <div>
        <img loading="lazy" src={require('../pictures/grand-celeb-14.jpg')} alt="ACS 2023 grand celebration teacher giving advice" />
      </div>
      <div>
        <img loading="lazy" src={require('../pictures/grand-celeb-15.jpg')} alt="ACS 2023 grand celebration med teacher giving advice" />
      </div>
      <div>
        <img loading="lazy" src={require('../pictures/grand-celeb-23.jpg')} alt="ACS 2023 grand celebration teacher group selfie" />
      </div>
    </Carousel>
  );
};

export default CarouselElement;

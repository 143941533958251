import React from 'react';
import CarouselElement from './CarouselElement';

const Header = () => {
  return (
    <div className="p-8 mb-20 md:px-24 md:pt-12 md:pb-24">
      <p className='text-center font-semibold text-2xl pb-8'>ACS Grand Celebration 2023</p>
      <p className='text-center font-semibold text-xl'> ২২ ব্যাচের BUET, DU, CKRUET, GST, CU, RU ও JU তে চান্সপ্রাপ্ত স্টুডেন্টদের সংবর্ধনা অনুষ্ঠান এর স্থিরচিত্র</p>
      <br />
      <CarouselElement></CarouselElement>
    </div >
  );
};

export default Header;
import React from 'react';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

const ReturnandRefund = () => {
  return (
    <div>
       <NavBar></NavBar>
      <section className='p-8 md:px-24 md:pt-12 md:pb-24 '>
      <p><strong>Refund and Return Policy</strong><br />
      <br />
        Thanks for shopping at ASG Shop. We appreciate the fact that you like to buy the stuff we build. We also make sure you have a rewarding experience while you&#8217;re exploring, evaluating and purchasing our products.<br />
        <strong>If there&#8217;s something wrong with the product you brought, or if you are not happy with it, you will not be able to issue a refund for your item.</strong></p>
        <br />
        <br />
        <p><strong>Refunds</strong><br />
        <br />
        We ASG Shop team commit ourselves to serving our customers with the best products. We follow certain policies to ensure transparency, efficiency and quality customer care.</p>
        <ol>
        We DO NOT allow returns on sold courses/products &#8211; online.
        </ol>
        <ol>
        Refunds are NOT given for any purchases made &#8211; be they online or in others.
        </ol>
        <ol>
        We DO NOT encourage exchanges of our products.
        </ol>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default ReturnandRefund;
import React from 'react';
import { Fade } from "react-awesome-reveal";
import { Link } from 'react-router-dom';
import Teachers from './Teachers';

const CourseandTeachers = () => {
  return (
    <div className='' id="courses">
      <p className='text-center text-2xl font-semibold mt-12'>আমাদের কোর্স সমূহ</p>
      <div className="p-8 mb-20 md:px-24 md:pt-12 md:pb-24">
        <div className='grid gap-8 justify-items-center grid-cols-1 md:grid-cols-2 bg-gray-100 p-8 md:p-12 rounded-lg mb-12 drop-shadow-xl'>
          <img loading="lazy" src={require('../pictures/HSC-23-engg.png')} alt="HSC-23 Engineering Private Batch" className="" />
          <div>
            <p className='font-bold text-base'>HSC-23 Engineering Private Batch</p>
            <br />
            <p>
              যাদের স্বপ্ন ইঞ্জিনিয়ার হওয়া এবং BUET,CKRUET,IUT,DU,MIST,BUTex সহ সব প্রতিযোগিতামূলক পরীক্ষায় সেরাদের সেরা হওয়া, যুক্ত হয়ে যাও।
            </p>
            <br />
            <p>ফিজিক্স নিবেন - <span className='font-medium'>নুমেরি সাত্তার অপার (বুয়েট)</span> ও <span className='font-medium'>অপুর্ব অপু (রুয়েট)</span></p>
            <p>কেমিস্ট্রি নিবেন - <span className='font-medium'>সঞ্জয় চক্রবর্তী (বুয়েট)</span></p>
            <p>ম্যাথ নিবেন - <span className='font-medium'>অভি দত্ত তুষার (বুয়েট)</span> ও <span className='font-medium'>কাজী রাকিবুল হাসান (বুয়েট)</span></p>
            <br />
            <Link to={"https://aparsclassroom.com/shop/admission/HSC_23/Engineering"} className="rounded-lg bg-blue-300 px-4 py-2 drop-shadow-xl hover:border-4 border-blue-400">Course details</Link>
          </div>
        </div>
        <div className='grid gap-8 justify-items-center grid-cols-1 md:grid-cols-2 bg-gray-100 p-8 md:p-12 rounded-lg mb-12 drop-shadow-xl'>
          <img loading="lazy" src={require('../pictures/HSC-23-varsity.jpg')} alt="HSC-23 Varsity Private Batch" className="" />
          <div>
            <p className='font-bold text-base'>HSC-23 Varsity Private Batch</p>
            <br />
            <p> ACS Varsity 23 batch এর প্রি - বুকিং শুরু হয়ে গেছে, যারা প্রি-বুকিং করবে তারা সর্বোচ্চ ডিসকাউন্ট পাবে। </p>
            <br />
            <p>শিক্ষক মন্ডলী:</p>
            <p>ফিজিক্স - নুমেরি সাত্তার অপার ও অপূর্ব অপু </p>
            <p>কেমিস্ট্রি - নাজমুস সাকিব</p>
            <p>ম্যাথ - অভি দত্ত তুষার, কাজী রাকিবুল হাসান </p>
            <p>বায়োলজি - হাসনাত শুভ্র </p>
            <br />
            <Link to={"https://aparsclassroom.com/shop/admission/HSC_23/Varsity/"} className="rounded-lg bg-blue-300 px-4 py-2 drop-shadow-xl hover:border-4 border-blue-400">Course details</Link>
          </div>
        </div>
        <div className='grid gap-8 justify-items-center grid-cols-1 md:grid-cols-2 bg-gray-100 p-8 md:p-12 rounded-lg mb-12 drop-shadow-xl'>
          <img loading="lazy" src={require('../pictures/medical-23.png')} alt="HSC-23 Medical Private Batch" className="" />
          <div>
            <p className='font-bold text-base'>HSC-23 Medical Private Batch</p>
            <br />
            <p> ২৩ ব্যাচের স্টুডেন্টরা সর্বশেষ ব্যাচগুলোর মধ্যে সবথেকে কম সময় পেয়েছে HSC তে, আর যদি ২২ ব্যাচের মতন HSC আর এডমিশনের ব্যবধান ৯০ দিনের মত হয় তাহলে ২৩ ব্যাচের মেডিকেল ক্যান্ডিডেটদের চ্যালেঞ্জটা  সবথেকে বেশি। তাই যদি এখন থেকেই HSC এর সাথে সাথে মেডিকেল নিয়ে এগিয়ে থাকা যায় তাহলেই এই চ্যালেঞ্জ মোকাবেলা করা যাবে।</p>
            <p>২৩ ব্যাচে আমরা ২২ ব্যাচের থেকেও বেশ কিছু ভালো আপগ্রেডেশন আনছি যা তোমরা কোর্স ট্রেইলারেই জানতে পারবে। আপাতত জেনে রাখো, এই একটা ব্যাচই তোমাদের মেডিকেল এডমিশন পর্যন্ত চলমান থাকবে, এটিই মেডিকেলের একমাত্র এবং পূর্নাঙ্গ ব্যাচ হবে ACS এর। ক্লাস শুরু হবে  “এপ্রিল মাসের ২৮ “ তারিখে।</p>
            <p> এখানেও ক্লাস নিবে হাসনাত শুভ্র সহ মো.নুমেরি সাত্তার অপার ভাইয়া, সঞ্জয় দাদা, সাকিব ভাইয়া আর ডা.তোফায়েল আহমেদ ভাইয়া। </p>
            <br />
            <Link to={"https://aparsclassroom.com/shop/admission/HSC_23/Medical/"} className="rounded-lg bg-blue-300 px-4 py-2 drop-shadow-xl hover:border-4 border-blue-400">Course details</Link>
          </div>
        </div>
      </div>

      <p className='text-center font-semibold text-2xl'>আমাদের অর্জন</p>
      <div className='p-8 mb-20 md:px-24 md:pt-12'>
        <Fade>
          <img  loading="lazy" src={require('../pictures/buet-first-ten.jpg')} alt="Engineering Private Batch" className="" />
        </Fade>
        <Fade className='grid place-content-center '>
          <img loading="lazy"  src={require('../pictures/iut-top.jpg')} alt="Engineering Private Batch" className=" object-center" />
        </Fade>
        <div className='grid gap-8 justify-items-center grid-cols-1 md:grid-cols-3 rounded-lg mb-12 drop-shadow-xl'>
          <Fade>
            <div className="max-w-sm bg-white rounded-lg shadowbg-gray-200 h-full">
              <div className="p-5">
                <img loading="lazy" className="rounded-t-lg h-40" src={require('../pictures/BUET_LOGOsvg.png')} alt="buet logo" />
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1999864427048283'>
                  <br />
                  <h5 className="mb-2 text-2xl font-bold tracking-tight">বুয়েটে সাফল্য - ৭৭৯ জন</h5>
                </a>
                <p className="mb-3 font-normal text-gray-700">আলহামদুলিল্লাহ, এখন পর্যন্ত ACS Engineering Admission Private Batch হতে BUET এ চান্সপ্রাপ্ত প্রথম ১০ জন্যের মধ্যে ৮ জন সহ, প্রথম ১৫০০ জনের মধ্যে সর্বমোট ৭৭৯ জন নিজে থেকেই জানিয়েছে তারা আমাদের ব্যাচের স্টুডেন্ট ছিল। তোমাদের এই অসামান্য অর্জনে ACS অংশীদার হতে পেরে খুবই গর্ববোধ করছে।</p>
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1999864427048283' className="inline-flex items-center px-3 py-2 text-sm font-medium text-center bg-blue-300 rounded-lg hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300" >
                  সবার নাম-তালিকা
                  <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                  </svg>
                </a>
              </div>
            </div>
          </Fade>
          <Fade>
            <div className="max-w-sm bg-white rounded-lg shadowbg-gray-200 h-full">
              <div className="p-5">
                <img loading="lazy"  className="rounded-t-lg h-40" src={require('../pictures/Islamic_University_of_Technology_(coat_of_arms).png')} alt="iut logo" />
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1983082955393097/'>
                  <br />
                  <h5 className="mb-2 text-2xl font-bold tracking-tight">IUT তে সাফল্য - ৬৯০ জন</h5>
                </a>
                <p className="mb-3 font-normal text-gray-700">আলহামদুলিল্লাহ, এখন পর্যন্ত ACS Engineering Admission Private Batch হতে IUT তে মেরিটপ্রাপ্ত প্রথম ২৫০০ জন্যের মধ্যে সর্বমোট ৬৯০ জন নিজে থেকেই জানিয়েছে তারা আমাদের ব্যাচের স্টুডেন্ট ছিল। পাশাপাশি Top 10 এর ৫ জন এবং Top 100 এর ৩৫ জন ACS থেকে চান্স পেয়েছে।</p>
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1983082955393097/' className="inline-flex items-center px-3 py-2 text-sm font-medium text-center bg-blue-300 rounded-lg hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300" >
                  সবার নাম-তালিকা
                  <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                  </svg>
                </a>
              </div>
            </div>
          </Fade>
          <Fade>
            <div className="max-w-sm bg-white rounded-lg shadowbg-gray-200 h-full">
              <div className="p-5">
                <img loading="lazy" className="rounded-t-lg h-40" src={require('../pictures/ckruet.png')} alt="ckruet logo" />
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1999108773790515/'>
                  <br />
                  <h5 className="mb-2 text-2xl font-bold tracking-tight">CKRUET সাফল্য - ১৮০০+ জন</h5>
                </a>
                <p className="mb-3 font-normal text-gray-700">আলহামদুলিল্লাহ, এখন পর্যন্ত ACS Engineering Admission Private Batch হতে CKRUET এ চান্সপ্রাপ্ত প্রথম ১-১০০ তে ৫৪ জন, ও ১০১-৫০০ তে ১৭৭ জন নিজে থেকেই জানিয়েছে তারা আমাদের ব্যাচের স্টুডেন্ট ছিল।</p>
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1999108773790515/' className="inline-flex items-center px-3 py-2 text-sm font-medium text-center bg-blue-300 rounded-lg hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300" >
                  সবার নাম-তালিকা
                  <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                  </svg>
                </a>
              </div>
            </div>
          </Fade>
          <Fade>
            <div className="max-w-sm bg-white rounded-lg shadowbg-gray-200  h-full">
              <div className="p-5">
                <img loading="lazy"  className="rounded-t-lg h-40" src={require('../pictures/du.png')} alt="du logo" />
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1987350954966297/'>
                  <br />
                  <h5 className="mb-2 text-2xl font-bold tracking-tight">ঢাবি সাফল্য - ১০১৪ জন</h5>
                </a>
                <p className="mb-3 font-normal text-gray-700">DU A unit এ ১০১৪ জন৷ আলহামদুলিল্লাহ আলহামদুলিল্লাহ। প্রতি ৫০ জন ঢাবিয়ান ২২ এর মধ্যে ২৭ জনই ACS এর৷ মানে প্রতি ২ জনে ১ জনেরও বেশি৷ </p>
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1987350954966297/' className="inline-flex items-center px-3 py-2 text-sm font-medium text-center bg-blue-300 rounded-lg hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300" >
                  সবার নাম-তালিকা
                  <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                  </svg>
                </a>
              </div>
            </div>
          </Fade>
          <Fade>
            <div className="max-w-sm bg-white rounded-lg shadowbg-gray-200 h-full">
              <div className="p-5">
                <img loading="lazy"  className="rounded-t-lg h-40" src={require('../pictures/Official_seal_of_Jahangirnagar_University.png')} alt="ju logo" />
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1998956960472363/'>
                  <br />
                  <h5 className="mb-2 text-2xl font-bold tracking-tight">জাবি সাফল্য - ৩৮৭ জন</h5>
                </a>
                <p className="mb-3 font-normal text-gray-700">আলহামদুলিল্লাহ , জাহাঙ্গীরনগর বিশ্ববিদ্যালয়ে ACS থেকে ৩৮৭ জন চান্স পেয়েছে।  </p>
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1998956960472363/' className="inline-flex items-center px-3 py-2 text-sm font-medium text-center bg-blue-300 rounded-lg hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300" >
                  সবার নাম-তালিকা
                  <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                  </svg>
                </a>
              </div>
            </div>
          </Fade>
          <Fade>
            <div className="max-w-sm bg-white rounded-lg shadowbg-gray-200 h-full">
              <div className="p-5">
                <div className='flex object-contain'>
                  <img loading="lazy" className="rounded-t-lg h-10 md:h-20" src={require('../pictures/cu.png')} alt="" />
                  <img loading="lazy" className="rounded-t-lg h-10 md:h-20 px-4" src={require('../pictures/ru.png')} alt="" />
                  <img loading="lazy" className="rounded-t-lg h-10 md:h-20" src={require('../pictures/Shahjalal_University_of_Science_and_Technology_logo.png')} alt="gst ru cu logo" />
                </div>
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1989403568094369/'>
                  <br />
                  <h5 className="mb-2 text-2xl font-bold tracking-tight">রাবি + চবি + GST সাফল্য - ৩৭৩৬ জন</h5>
                </a>
                <p className="mb-3 font-normal text-gray-700">আলহামদুলিল্লাহ , GST এ ২১০৩ জন, চবি তে ৫৬১ জন, রাবি তে ১০৭২ জন চান্স পেয়েছে। </p>
                <a href='https://www.facebook.com/groups/aparsclassroom/permalink/1989403568094369/' className="inline-flex items-center px-3 py-2 text-sm font-medium text-center bg-blue-300 rounded-lg hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300" >
                  সবার নাম-তালিকা
                  <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                  </svg>
                </a>
              </div>
            </div>
          </Fade>
        </div>

      </div>
      <Teachers></Teachers>
    </div >
  );
};

export default CourseandTeachers;